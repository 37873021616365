import React from 'react'
import "../assets/css/components/Loader.css"

function Loader() {
  return (
    <div className='outer'>
        <div className="inner"></div>
    </div>
  )
}

export default Loader