import React from 'react'
import AllCenter from '../layouts/AllCenter'
import { Link } from 'react-router-dom'

const Forbidden = () => {
  return (
    <AllCenter>
        <h1 className="mt-4">Forbidden Access!</h1>
        <h4>You are not authorized to view this resource. Please return to the home page.</h4>
        <Link to={"/"}>
            <button className="btn btn-dark">Home</button>
        </Link>
    </AllCenter>
  )
}

export default Forbidden