import React, { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from "uuid"
import Loader from '../Loader'
import useAxios from '../../Hooks/auth/useAxios'
import RenderForm from './RenderForm'

const CreateForm = () => {
  const api = useAxios()

  const [test, setTest] = useState({});
  const [formElems, setFormElems] = useState([])
  const [errMsg, setErrMsg] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [toggler, setToggler] = useState(false) // state that toggles b/w true and false on every form submission
  
   // eslint-disable-next-line
   const testId = useMemo(() => uuidv4(), [toggler])   // passing toggler in the depedency array to generate a new
                                                       // testId after every form submission
   
   useEffect(()=>{
     setTest(prev=>({...prev, id:testId}))
    // eslint-disable-next-line
  }, [toggler])
  
  const handleSubmit = async (e, elems, formula) => {
    e.preventDefault()
    e.stopPropagation()

    if(!validateParams(elems)) {
      return
    }
    setIsLoading(true)
    const isTestCreated = await postTest(formula);
    if(!isTestCreated) {
      setErrMsg("Unable to create record. Please try again.")
      setIsLoading(false)
      return
    }

    const isElemCreated = await postElems(elems);
    // console.log(isElemCreated);
    if(!isElemCreated) {
      setErrMsg("Unable to create record. Please try again.")
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setErrMsg("")
    handleFormSubmitSuccess()
  }

  const handleFormSubmitSuccess = () => {
    alert("Test created successfully!")
    resetForm()
  }
  
  const resetForm = () => {
    setTest(null)
    setFormElems([])
    setErrMsg("")
    setToggler(prev=>!prev)
  }

  const validateParams = (elems)=>{
    if(!test?.name || test?.name===undefined || test?.name.trim()==="") {
      setErrMsg("Please fill Test Name");
      return false;
    }

    if(!test?.description || test?.description===undefined || test?.description.trim()==="") {
      setErrMsg("Please fill Description");
      return false;
    }

    if(!test?.specialization || test?.specialization===undefined || test?.specialization.trim()==="") {
      setErrMsg("Please fill Specialization");
      return false;
    }

    if(elems.length === 0) {
      setErrMsg("Please add fields to the form.")
      return false;
    }
    return true
  }

  const postTest = async (formula) => {
    try {
      await api.post('/test',
        { // body data
          id:testId,
          name: test?.name.trim(),
          description: test?.description.trim(),
          specialization: test?.specialization.trim(),
          formula: formula.trim()
        },
        { // config
          headers: {'Content-Type':'application/json'}
        }
      )
      return true;
    } catch (e) {
      console.log(e);
    }
    return false
  }

  const postElems = async (elems) => {
    try {
        await api.post(`/formElem/${testId}`, 
         // body data
        elems,
        { // config
          headers: {'Content-Type':'application/json'}
        })
        return true
    } catch(e) {
        console.error(e);
        return false;
    }
  }

  useEffect(()=>{
    setIsLoading(false)
  }, [])

  return (
    <div>
    {isLoading ?
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      :
      <div>
          <h1 className=''>Create Form</h1>
          <hr />
          <div className="input-group mb-3">
            <span className="input-group-text" id="create-test-name-lbl">Test Name</span>
            <input type="text"
                  id="create-test-name-input"
                  className="form-control"
                  placeholder="Enter test name"
                  aria-label="Test Name"
                  aria-describedby="basic-addon1"
                  value={test?.name ?? ""}
                  onChange={(e)=>setTest(prev=>({...prev, name:e.target.value}))}/>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="create-test-desc-lbl">Description</span>
            <input type="text"
                  id="create-test-desc-input"
                  className="form-control"
                  placeholder="Enter description"
                  aria-label="Test Desc"
                  aria-describedby="basic-addon1"
                  value={test?.description ?? ""}
                  onChange={(e)=>setTest(prev=>({...prev, description: e.target.value}))}/>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="create-test-specialization-lbl">Specialization</span>
            <input type="text"
                  id="create-test-specialization-input"
                  className="form-control"
                  placeholder="Enter specialization"
                  aria-label="Specialization"
                  aria-describedby="basic-addon1"
                  value={test?.specialization ?? ""}
                  onChange={(e)=>setTest(prev=>({...prev, specialization: e.target.value}))}/>
          </div>

          <h3 className='mt-5'>Form Preview</h3>
          <hr />

          <RenderForm test={test}
                      elems={formElems}
                      handleSubmit={handleSubmit}
                      showScore={true}
                      allowControls={true} errMsg={errMsg}/>
        </div>
      }
    </div>
  )
}

export default CreateForm