import React, { useCallback, useEffect, useState } from 'react'
// import Form from '../components/forms/Form'
import { useParams } from 'react-router-dom'
import Loader from '../components/Loader'
import useAxios from '../Hooks/auth/useAxios'
import RenderForm from '../components/forms/RenderForm'

function FormView() {
  const params = useParams()
  const [test, setTest] = useState(null)
  const [elems, setElems] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const api = useAxios();

  const getTest = useCallback(async() => {
    try {
      const res = await api.get(`test/${params.testId}`);
      setTest(res.data)
    } catch(e) {
      console.log(e);
      setTest(null)
    }
  // eslint-disable-next-line 
  },[params.testId])

  const getFormElems = useCallback(async () => {
      try {
          const res = await api.get(`/formElem/${params.testId}`)
          setElems(res.data)
          setIsLoading(false)
      } catch (e) {
          console.log(e);
          setElems(null)
      }
      // eslint-disable-next-line
  }, [params.testId])

  const handleSubmit = (e)=> {
      e.preventDefault();
      e.stopPropagation();
  }

  const getData = useCallback(async () => {
    setIsLoading(true)
    await getTest();
    await getFormElems()
    setIsLoading(false)
  },[getTest, getFormElems])

  useEffect(()=>{
    getData()
  },[getData])
  

  return (
      <div className="container" style={{maxWidth:"600px"}}>
        {(!test || !elems) ?
          isLoading ? 
              <div className="d-flex justify-content-center">
                  <Loader />
              </div>
              :
              <>
                <h1 className='text-center mt-5'> Unable to find the resource.</h1>
                <h4 className='text-center mt-2'> Please go back to Home.</h4>
              </>
          :
          <RenderForm test={test} elems={elems} allowControls={false} handleSubmit={handleSubmit} showScore={true}/>
        }
      </div>
  )
}

export default FormView