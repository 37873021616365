import React, { useEffect, useMemo, useState } from 'react'
import "../../../assets/css/components/form/sections/sectionStyle.css"
import {v4 as uuidv4} from "uuid"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashCan} from "@fortawesome/free-regular-svg-icons"

function AddNumberInput({preset=null, handleCreate, handleDelete, handleEdit}) {
/**
   * {
      "id":"1",
      "testId":"1",
      "type": "number",
      "required": true,
      "units": "mg/dL",
      "attr": {
          "label": "pH",
          "lblNotes": "",
          "placeholder": "within 4.8-8.2",
          "min": 0,
          "max": 14
      }
    },
    */
  
  const uuid = useMemo(() => uuidv4(), [])
  const [isRqd, setIsRqd] = useState(false);
  const [units, setUnits] = useState("");
  const [label, setLabel] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [notes, setNotes] = useState("");
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);
  const [errMsg, setErrMsg] = useState("");

  useEffect(()=>{
    if(!preset) return;
    setIsRqd(preset.required)
    setUnits(preset.units)
    setLabel(preset.attr.label)
    setPlaceholder(preset.attr.placeholder)
    setNotes(preset.notes)
    setMin(preset.attr.min)
    setMax(preset.attr.max)
  }, [preset])

//   useEffect(()=>{
//     console.log({
//         "id":uuid,
//         "type": "number",
//         "required": isRqd,
//         "units": units,
//         "attr": {
//             "label": label,
//             "lblNotes": notes,
//             "placeholder": placeholder,
//             "min": min,
//             "max": max
//         }
//     })
//   }, [isRqd, units, label, placeholder, notes, min, max, uuid])

  return (
    <form className='rounded outline-dark p-4'>
        <h5 className='mb-3'>Number Input</h5>
        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-lbl`}>Label <sup className='text-danger ms-1'>*</sup></span>
          <input type="text" id={`${uuid}-lbl-input`} className="form-control" placeholder="Enter Label" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={label} onChange={(e)=>{setLabel(e.target.value)}}/>
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-placeholder`}>Placeholder <sup className='text-danger ms-1'>*</sup></span>
          <input type="text" id={`${uuid}-placeholder-input`} className="form-control" placeholder="Enter Placeholder" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={placeholder} onChange={(e)=>{setPlaceholder(e.target.value)}}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-units`}>Units</span>
          <input type="text" id={`${uuid}-units-input`} className="form-control" placeholder="Enter Units" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={units} onChange={(e)=>{setUnits(e.target.value)}}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-min`}>Min</span>
          <input type="number" id={`${uuid}-min-input`} className="form-control" placeholder="Enter Min" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={min ?? ''} onChange={(e)=>{setMin(e.target.value)}}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-max`}>Max</span>
          <input type="number" id={`${uuid}-max-input`} className="form-control" placeholder="Enter Max" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={max ?? ''} onChange={(e)=>{setMax(e.target.value)}}/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-notes`}>Notes</span>
          <input type="text" id={`${uuid}-notes-input`} className="form-control" placeholder="Enter Notes" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={notes} onChange={(e)=>{setNotes(e.target.value)}}/>
        </div>
        <div className="form-check form-switch mb-3">
          <input className="form-check-input" type="checkbox" role="switch" id={`${uuid}-reqd`} checked={isRqd} 
                 value={isRqd} onChange={(e)=>{setIsRqd(prev=>!prev)}}/>
          <label className="form-check-label" htmlFor={`${uuid}-reqd`}>Required</label>
        </div>
        
        {<p className="text-danger">{errMsg}</p>}

        <div className="d-flex">
            <button className='btn btn-primary me-2' onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        if(!label || label===undefined || label.trim()==='') {
                            setErrMsg("Label is a required field.")
                            return;
                        } else if(!placeholder || placeholder===undefined || placeholder.trim()==='') {
                            setErrMsg("Placeholder is a required field.")
                            return;
                        }

                        const obj = {
                            "id":(!preset) ? uuid : preset.id,
                            "type": "number",
                            "required": isRqd,
                            "units": units.trim(),
                            "attr": {
                                "label": label.trim(),
                                "placeholder": placeholder.trim(),
                                "min": min,
                                "max": max
                            },
                            "notes": notes.trim(),
                        }

                        if(!preset) {
                            handleCreate(obj);
                        } else {
                            handleEdit(obj);
                        }
            }}>
                {!preset ? "Create Field" : "Edit"}
            </button>
            <button className='btn btn-danger' onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        handleDelete(uuid);
            }}>
                <FontAwesomeIcon icon={faTrashCan}/>
            </button>
        </div>
    </form>
  )
}

export default AddNumberInput