import React, { useEffect, useState } from 'react'
import "../assets/css/components/ListItem.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faStar as faRegStar, faTrashCan} from "@fortawesome/free-regular-svg-icons"
import {faClose, faEllipsisV, faStar as faSolidStar} from "@fortawesome/free-solid-svg-icons"
import { useAuthContext } from '../contexts/AuthContext'
import { USER_ROLES } from '../config/config'

function ListItem({testId, title, body, handleEdit, handleDelete, isFavourite=false}) {
    const [isFav, setIsFav] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const authContext = useAuthContext();

    useEffect(()=>{
        setIsFav(isFavourite)
    }, [isFavourite])

  return (
    <div className='list-item my-3 p-3'>
        <div className="d-flex">
            <div className="flex-grow-1">
                <h4 className=''>{title}</h4>
                <p className='m-0'>{body}</p>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center flex-grow-0">
                {showOptions && 
                    <div className='outline-dark rounded d-flex flex-column flex-md-row align-items-center p-2' onClick={e=>{e.preventDefault();e.stopPropagation()}}>
                        <FontAwesomeIcon icon={isFav ? faSolidStar : faRegStar} style={{color:"purple"}} 
                                        onClick={(e)=>{
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setIsFav(prev=>!prev)
                                        }
                        }/>
                        {authContext.canUserAccess(USER_ROLES.ADMIN) && 
                            <>
                                <button className='btn btn-primary mt-2 ms-md-2 mt-md-0' 
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation()
                                            handleEdit(testId);
                                        }}>
                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                </button>
                                <button className='btn btn-danger mt-2 ms-md-2 mt-md-0' 
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation()
                                            handleDelete(testId);
                                }}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </button>
                            </>
                        }
                    </div>
                    }
                    <FontAwesomeIcon icon={(showOptions) ? faClose : faEllipsisV} className='btn btn-large mt-2 ms-md-2 mt-md-0 text-dark'
                            onClick={e=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setShowOptions(prev=>!prev)
                            }}>
                        {showOptions ? "Hide" : "Option"}
                    </FontAwesomeIcon>
            </div>
        </div>
    </div>
  )
}

export default ListItem