import React from 'react'

const AllCenter = ({children}) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
        {children}
    </div>
  )
}

export default AllCenter