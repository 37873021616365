import React from 'react'
import LoginForm from '../../components/forms/auth/LoginForm'

const LoginView = () => {
  return (
    <div className="mx-auto" style={{maxWidth:"450px"}}>
      <LoginForm />
    </div>
  )
}

export default LoginView