import { createBrowserRouter } from "react-router-dom"
import App from "./App"
import Home from "./views/Home"
import FormView from "./views/FormView"
import Create from "./views/Create"
import Error from "./views/Error"
import SignupView from "./views/auth/SignupView"
import LoginView from "./views/auth/LoginView"

export const router = createBrowserRouter([
    {
        path:"/",
        element: <App />,
        errorElement: <Error />,
        children: [
            { path:"/", element: <Home /> },
            { path:"/calc/:testId", element: <FormView /> },
            { path:"/create", element: <Create /> },
            { path:"/signup", element: <SignupView/> },
            { path:"/login", element: <LoginView/> },
            { path:"/about", element: <h1 className="text-center mt-4">About Page</h1> },
            { path:"/contact", element: <h1 className="text-center mt-4">Contact Us Page</h1> }
        ]
    }
])