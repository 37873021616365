import React, { useRef, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AddRadioInput from './sections/AddRadioInput';
import AddNumberInput from './sections/AddNumberInput';
import { roundOff } from '../../utils/math/mathUtils';
const math = require("mathjs")

const RenderForm = ({test={}, elems=[], allowControls=false, handleSubmit, showScore=false, errMsg=""}) => {
    /** Constants */
    const editorTypes = {NONE:"none", NUMBER:"number", RADIO:"radio"}

    /** States */
    const [formStates, setFormStates] = useState({});
    const [formElems, setFormElems] = useState([])
    const [editorType, setEditorType] = useState(editorTypes.NONE)
    const [editElem, setEditElem] = useState(null)
    const [score, setScore] = useState(0);
    const [scoreErr, setScoreError] = useState("")
    const [formula, setFormula] = useState("");
    const [formulaErr, setFormulaErr] = useState("")
    const [isFormulaCheatsheetVisible, setIsFormulaCheatsheetVisible] = useState(false)
    const [showPreciseScore, setShowPreciseScore] = useState(false)

    /** Refs */
    const formulaInputRef = useRef(null);

    useEffect(()=>{
        if(test?.formula) {
            setFormula(test?.formula)
        }
    },[test?.formula])

    useEffect(()=>{
        setFormElems(elems)
    }, [elems])

    const handleCreate = (formField)=> {
        setFormElems(prev=>[...prev, {...formField, testId: test.id}])
        setEditorType(editorTypes.NONE)
        setEditElem(null)
    }

    const handleDelete = (id)=> {
        setFormElems(prev=>prev.filter((item)=>item.id!==id))
        setEditorType(editorTypes.NONE)
        setEditElem(null)
    }

    const handleNumberEdit = (elem)=> {
        setEditElem({...elem, testId: test.id});
        setEditorType(editorTypes.NUMBER)
    }

    const handleRadioEdit = (elem)=> {
        setEditElem({...elem, testId: test.id});
        setEditorType(editorTypes.RADIO)
    }

    const handleEdit = (elem)=> {
        setFormElems(prev=>prev.map(item=>(item.id === elem.id) ? {...elem, testId: test.id} : item))
        setEditorType(editorTypes.NONE)
    }

    const getElemIdByLabel = useCallback((name) => {
        for(let i = 0;i<formElems.length;++i) {
            if(formElems[i].attr.label===name) return formElems[i].id;
        }
        return undefined
    },[formElems])

    const checkFormFieldsInFormulaFilled = useCallback((exp="") => {
        if(!exp || exp==="") return true
        const regex = new RegExp("\\[.*?\\]", "gi");
        const matches = exp.match(regex)
        if(!matches) return true

        for(const id of exp.match(regex)) {
            const val = formStates[getElemIdByLabel(id.substring(1,id.length-1))]
            if(!val || val==="") return false
        }
        return true
    },[formStates, getElemIdByLabel])

    const convertLabelsToIds = useCallback((exp)=>{
        if(!exp || exp==="") return ""
        const regex = new RegExp("\\[.*?\\]", "gi");
        return exp.replace(regex, (match)=>`[${getElemIdByLabel(match.substring(1, match.length-1))}]`)
    },[getElemIdByLabel])

    const convertIdsToVals = useCallback((exp) => {
        if(!exp || exp==="") return ""
        const regex = new RegExp("\\[.*?\\]", "gi");
        return exp.replace(regex, (match)=>`${formStates[(match.substring(1, match.length-1))]}`)
    },[formStates])

    const isValidExpr = (exp="")=>{
        const regex = new RegExp("\\[\\]|\\]\\[", "gi")
        const matches = exp.match(regex)
        return (!matches)
    }

    const evaluateExpr = (exp)=>{
        if(!exp || exp==="") return undefined;
        try {
            return roundOff(math.evaluate(exp))
        } catch(e) {
            setFormulaErr("Invalid expression!");
        }
        return undefined
    }

    const areReqdFieldsFilled = useCallback(()=>{
        for(let i = 0;i<formElems.length;++i) {
            const elem = formElems[i];
            if(elem.required && (!formStates[elem.id] || formStates[elem.id]===undefined || formStates[elem.id]==="")) {
                return false
            }
        }
        return true
    },[formElems, formStates])

    const validateFormula = useCallback((expr) => {
        if(!expr || expr==="") {
            setFormulaErr("Please enter a formula to validate");
            return
        }

        if(!isValidExpr(expr)) {
            setFormulaErr("Invalid Expression!");
            return
        }

        if(!checkFormFieldsInFormulaFilled(expr)) {
            setFormulaErr("Please fill all the input fields to evaluate the score.");
            return
        }
        const val = evaluateExpr(convertIdsToVals(convertLabelsToIds(expr)))
        if(val===undefined) {
            setFormulaErr("Formula syntax error.");
            return
        }

        if(isNaN(val)) {
            setFormulaErr("Invalid formula. Possible reasons could be division by 0 or taking log/sqare root of a negative number, etc.");
            return
        }
        return true
    }, [checkFormFieldsInFormulaFilled, convertIdsToVals, convertLabelsToIds])

    const calcScore = useCallback(() => {
        if(!areReqdFieldsFilled()) {
            setScoreError("Please fill all the required fields");
            setScore(0)
            return
        }
        if(!formula || formula==="") {
            let tempScore = 0;
            for (const key in formStates) {
                const floatVal = Number.parseFloat(formStates[key])
                if(!isNaN(floatVal)) {
                    tempScore+=floatVal
                } else {
                    setScoreError("Please fill out all the required fields");
                    setScore(0)
                    return
                }
            }
            setScore(tempScore)
        } else {
            if(!validateFormula(formula)) {
                setScoreError("Formula Error")
                setScore(0)
                return
            }
            setScore(evaluateExpr(convertIdsToVals(convertLabelsToIds(formula))))
        }
        setFormulaErr("")
        setScoreError("")
    },[formStates, areReqdFieldsFilled, convertIdsToVals, convertLabelsToIds, formula, validateFormula])

    useEffect(()=>{
        const fs = {}
        formElems.forEach(elem => {
            switch (elem.type) {
                case "number":
                    fs[elem.id] = ''
                    break
                case "radio":
                    fs[elem.id] = ""
                    break
                default:
                    break
            }
        });
        setFormStates(prev=>({...prev, ...fs}))
    },[formElems])

    useEffect(()=>{
        calcScore()
    }, [formStates, calcScore, formula])

    const handleButtonClick = (value) => {
        const input = formulaInputRef.current; // Get the input element
        const start = input.selectionStart; // Get the starting cursor position
        const end = input.selectionEnd; // Get the ending cursor position

       // Modify the value with inserted text
       const newValue = formula.slice(0, start) + value + formula.slice(end);

       // Update the state to trigger a re-render
       setFormula(newValue);

       // Restore the cursor position after React re-renders
       setTimeout(() => {
           input.selectionStart = input.selectionEnd = start + value.length;
       }, 0);
      };

    return (
        <div>
        <h1 className='mt-2'>{test?.name ?? ""}</h1>
        <p className='text-grey mb-4 '>{test?.description ?? ""}</p>
        {/* Render form elements */}
        {formElems.map((elem, index)=>{
            switch(elem.type) {
                case "number":
                    return (
                    // <div key={`container-${elem.id}`} className="row my-3">
                    <div key={`container-${elem.id}`} className="d-flex">
                    <div className="row my-3 flex-grow-1">
                        <div className="col-sm-6">
                            <h6 htmlFor="basic-url" className="form-label text-break">{elem.attr.label}{elem.required && <sup className='text-danger ms-1'>*</sup>}</h6>
                            {/* <p htmlFor="basic-url" className="form-label text-wrap">{elem.attr.label}</p> */}
                        </div>
                        <div className="col-sm-6">
                            <div className="input-group">
                            <input type={elem.type}
                                    className="form-control"
                                    id={elem.id}
                                    value={formStates[elem.id] ?? ''}
                                    aria-describedby={`lbl-${elem.id} form-item-notes-${elem.id}`}
                                    placeholder={elem.attr.placeholder ?? ""}
                                    onChange={e=>setFormStates(prev=>({...prev, [elem.id]:e.target.value}))}
                                    />
                                {elem.units && <span className="input-group-text" id={`lbl-${elem.id}`}>{elem.units}</span>}
                            </div>
                            {elem.notes && <div className="form-text" id={`form-item-notes-${elem.id}`}>{elem.notes}</div>}
                        </div>
                        </div>
                        {allowControls &&
                            <>
                                <button className='btn btn-primary ms-2 ht-40'
                                        onClick={(e)=>{
                                        e.preventDefault()
                                        e.stopPropagation()
                                        handleNumberEdit(elem);
                                }}>
                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                </button>
                                <button className='btn btn-danger ms-2 ht-40'
                                        onClick={(e)=>{
                                        e.preventDefault()
                                        e.stopPropagation()
                                        handleDelete(elem.id);
                                }}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </button>
                            </>}
                    </div>
                    )

                case "radio":
                    return (
                    <div  key={elem.id} className="d-flex my-4">
                        <div className="row flex-grow-1">
                            <div className="col-sm-6">
                                <h6>{elem.attr.label}{elem.required && <sup className='text-danger ms-1'>*</sup>}</h6>
                            </div>
                            <div className="col-sm-6">
                                <fieldset name={`field-${elem.id}`}>
                                    <div className="btn-group-vertical w-100" role="group" aria-label="Basic radio toggle button group">
                                        {elem.fields.map((field, idx)=>{
                                            return (
                                                <div key={`${index}-${idx}`} className="btn-group">
                                                    <input type={elem.type}
                                                        className="btn-check"
                                                        value={field.val ?? 0}
                                                        name={`field-${elem.id}`}
                                                        id={`${elem.id}-${field.id}`}
                                                        autoComplete="off"
                                                        onChange={e=>setFormStates(prev=>({...prev, [elem.id]:e.target.value}))}
                                                    />
                                                    <label className="btn btn-outline-dark h-100 d-flex" htmlFor={`${elem.id}-${field.id}`}>
                                                        <span className='flex-grow-1'>{field.label}</span>
                                                        {(field.val !== null) && (field.val!==undefined) &&
                                                            <>
                                                                <span className={`ms-3 badge text-bg-${!isNaN(field.val) && (field.val >= 0) ? `success` : `danger`} d-flex flex-column justify-content-center`}>
                                                                    {`${!isNaN(field.val) && (field.val >= 0) ? `+` : ``}`+ (field.val ?? "")}
                                                                </span>
                                                            </>
                                                        }
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </fieldset>
                                {elem.notes && <div className="form-text" id={`form-item-notes-${elem.id}`}>{elem.notes}</div>}
                            </div>
                        </div>
                        {allowControls &&
                            <>
                                <button className='btn btn-primary ms-2 ht-40'
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation()
                                            handleRadioEdit(elem);
                                        }}>
                                    <FontAwesomeIcon icon={faPenToSquare}/>
                                </button>
                                <button className='btn btn-danger ms-2 ht-40'
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation()
                                            handleDelete(elem.id);
                                        }}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </button>
                            </>
                        }
                    </div>
                    )
                default: return <></>
            }
        })}
        {allowControls &&
            <>
                <h3 className='mt-5'>Add Fields</h3>
                <hr />
                <div className="d-flex my-3">
                    <button className="btn btn-primary me-2" onClick={()=>{setEditElem(null);setEditorType(prev=>(prev!==editorTypes.NUMBER) ? editorTypes.NUMBER : editorTypes.NONE)}}>Number</button>
                    <button className="btn btn-primary me-2" onClick={()=>{setEditElem(null);setEditorType(prev=>(prev!==editorTypes.RADIO) ? editorTypes.RADIO : editorTypes.NONE)}}>Radio</button>
                </div>

                <div className="editor">
                    {(editorType===editorTypes.NONE ?
                        <></> :
                        (editorType===editorTypes.NUMBER) ? <AddNumberInput preset={editElem}
                                                                            handleCreate={handleCreate}
                                                                            handleDelete={handleDelete}
                                                                            handleEdit={handleEdit}/> :
                        (editorType===editorTypes.RADIO) ? <AddRadioInput preset={editElem}
                                                                            handleCreate={handleCreate}
                                                                            handleDelete={handleDelete}
                                                                            handleEdit={handleEdit}/>
                        : <></>
                    )}
                </div>

                <h3 className='mt-5'>Formula</h3>
                <hr />

                <div className="outline-dark rounded p-3">
                    <h5>Form Fields</h5>
                    {formElems.length===0 ?
                        <p className='text-muted'>The form fields that you add will appear here</p>
                        :
                        <>
                        {formElems.map((elem,index)=>{
                            return (
                            <button key={`${index}-${elem.id}`} className='btn btn-secondary me-2'
                                    onClick={(e)=>{
                                        handleButtonClick("[" + elem.attr.label + "]");
                                    }}
                                    onMouseDown={e=>e.preventDefault()}
                            >
                                {elem.attr.label}
                            </button>
                            )})}
                        </>
                    }
                </div>

                <div className="my-3">
                    <textarea type="text"
                           className="form-control"
                           id="score-formula"
                           aria-describedby="score-formula-help"
                           placeholder='Enter formula'
                           value={formula}
                           ref={formulaInputRef}
                           onChange={e=>{setFormula(e.target.value)}}
                           />

                    <p className="text-danger">{formulaErr}</p>

                    <p className='text-secondary m-0' style={{cursor:"pointer", textDecoration:"underline"}}
                        onClick={()=>setIsFormulaCheatsheetVisible(prev=>!prev)}>
                        {`${isFormulaCheatsheetVisible ? "Hide" : "Show"} formula cheatsheat:`}
                    </p>
                    {isFormulaCheatsheetVisible &&
                        <div id="score-formula-help" className="form-text">
                            <h6>Formula cheatsheet and rules:</h6>
                            <ul>
                                <li>Addition: <strong>x + y</strong>, where x and y are any 2 numbers.</li>
                                <li>Subtraction: <strong>x - y</strong>, where x and y are any 2 numbers.</li>
                                <li>Multiplication: <strong>x * y</strong>, where x and y are any 2 numbers.</li>
                                <li>Division: <strong>x / y</strong>, where x and y are any 2 numbers.</li>
                                <li>Logarithm: <strong>log(x, y)</strong>, where x = any number and y = base.</li>
                                <li>Natural log (ln(x)): <strong>log(x, e)</strong> where e = euler's constant.</li>
                                <li>Exponents (x<sup>y</sup>): <strong>x^y or pow(x,y)</strong> where x = any number and y is the power.</li>
                                <li>Square root (√x): <strong>sqrt(x)</strong> where x = any number.</li>
                            </ul>
                        </div>
                    }
                </div>

                <button className='btn btn-success mb-3'
                        onClick={e=>{handleSubmit(e,formElems,formula)}}>
                    Done <FontAwesomeIcon icon={faCheck}/>
                </button>

                <p className="text-danger mb-3">{errMsg}</p>
            </>
        }

        {showScore &&
            <div className="form-response-container my-3 bg-success text-light p-4 rounded sticky-bottom">
                <h1>{(scoreErr==="") ? "Score:" : "Result"}</h1>
                <div className="d-flex align-items-baseline">
                    <h2>{(scoreErr==="") ? `${showPreciseScore ? score : roundOff(score,0)}` : ``}</h2>
                    <p className='ms-2 my-0'>{(scoreErr==="") ? ((score===1) ? "point" : "points"):""}</p>
                </div>
                <p>{(scoreErr==="") ? "" : scoreErr}</p>
                {scoreErr==="" && 
                    <p  className="m-0" style={{cursor:"pointer", textDecoration:"underline"}}
                        onClick={()=>setShowPreciseScore(prev=>!prev)}>
                        {`${showPreciseScore ? "Hide" : "Show"} precise score`}
                    </p>
                }
            </div>
        }
    </div>
    )
}

export default RenderForm