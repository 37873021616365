import React, { useState } from 'react'
import "../../../assets/css/components/form/sections/sectionStyle.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashCan} from "@fortawesome/free-regular-svg-icons"
import {faCheck} from "@fortawesome/free-solid-svg-icons"

const RadioOption = ({uuid, handleCancelAdding, handleAddOption}) => {
    const [label, setLabel] = useState("");
    const [val, setVal] = useState('');
    const [errMsg, setErrMsg] = useState("");

    return (
        <div className="outline-dark rounded p-3 mb-3">
            <div className="input-group mb-3">
                <span className="input-group-text" id={`${uuid}-option-label`}>Option Label<sup className='text-danger ms-1'>*</sup></span>
                <input type="text" id={`${uuid}-option-label-input`} className="form-control" placeholder="Enter Option Label" aria-label="Test Name" aria-describedby="basic-addon1"
                        value={label} onChange={(e)=>{setLabel(e.target.value)}}/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text" id={`${uuid}-val`}>Value<sup className='text-danger ms-1'>*</sup></span>
                <input type="number" id={`${uuid}-val-input`} className="form-control" placeholder="Enter Value" aria-label="Test Name" aria-describedby="basic-addon1"
                        value={val ?? ''} onChange={(e)=>{setVal(e.target.value)}}/>
            </div>
            
            <p className="text-danger mb-3">{errMsg}</p>

            <button className='btn btn-success' 
                    onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        if(!label || label===undefined || label.trim()==='') {
                            setErrMsg("Please enter option label.")
                            return
                        } else if(!val || val===undefined || val==='') {
                            setErrMsg("Please enter Value.")
                            return
                        }
                        handleAddOption({id:uuid, label:label.trim(), val})
            }}>
                <FontAwesomeIcon icon={faCheck}/>
            </button>
            <button className='btn btn-danger ms-2'
                    onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        handleCancelAdding(uuid)
            }}>
                <FontAwesomeIcon icon={faTrashCan}/>
            </button>
        </div>
    )
}

export default RadioOption