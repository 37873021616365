import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
const data = require("../../../assets/data/country_dial_info.json")
const BASE_URL = process.env.REACT_APP_BASE_URL
const MIN_PW_LEN = 8

const SignupForm = () => {
  const navigate = useNavigate()

  const countryCodeSearchRef = useRef(null)

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [confirmPw, setConfirmPw] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [contactNum, setContactNum] = useState("")

  const [countryCodeSearchStr, setCountryCodeSearchStr] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [hasTriedSubmission, setHasTriedSubmission] = useState(false);

  const authContext = useAuthContext();

  useEffect(()=>{
    if(authContext.token) {
      navigate("/")
    }
  })

  const resetForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPw("")
    setConfirmPw("")
    setCountryCode("")
    setContactNum("")
    setHasTriedSubmission(false)
    setErrMsg("")
    countryCodeSearchStr("")
  }

  const handleSubmit = async ()=>{
    if(!validateParams()) return;
    const url = BASE_URL + "/user"
    try {
      await axios({
        method: 'post',
        url: url,
        headers: {"Content-Type":"application/json"},
        data:  {
          email:email.trim().toLowerCase(),
          password: pw.trim(),
          contactNumber: {
              countryCode: countryCode,
              number:contactNum.trim()
          },
          firstName:firstName.trim().toLowerCase(),
          lastName:lastName.trim().toLowerCase()
        }
      })
      navigate("/login")
      resetForm()
    } catch(e) {
      setErrMsg(e.response?.data?.message)
    }
  }

  const validateParams = useCallback(()=>{
    if(firstName===undefined || firstName==="") {
      if(hasTriedSubmission) {
        setErrMsg("First name cannot be blank.");
      }
      return false;
    }

    if(lastName===undefined || lastName==="") {
      if(hasTriedSubmission) {
        setErrMsg("Last name cannot be blank.");
      }
      return false;
    }

    if(email===undefined || email==="") {
      if(hasTriedSubmission) {
        setErrMsg("Email cannot be blank.");
      }
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(email)) {
      if(hasTriedSubmission) {
        setErrMsg("Please enter a valid email address.");
      }
      return false;
    }

    if(pw===undefined || pw==="") {
      if(hasTriedSubmission) {
        setErrMsg("Password cannot be blank.");
      }
      return false;
    }
    
    if(pw.length < MIN_PW_LEN) {
      if(hasTriedSubmission) {
        setErrMsg(`Password must be ${MIN_PW_LEN} characters long.`);
      }
      return false;

    }

    if(pw!==confirmPw) {
      if(hasTriedSubmission) {
        setErrMsg("Passwords do not match");
      }
      return false;
    }

    if(countryCode===undefined || countryCode==="") {
      if(hasTriedSubmission) {
        setErrMsg("Please select a country code.");
      }
      return false;
    }

    if(contactNum===undefined || contactNum==="") {
      if(hasTriedSubmission) {
        setErrMsg("Contact number cannot be blank.");
      }
      return false;
    }
    setErrMsg("")
    return true;
  },[firstName,lastName,contactNum,countryCode,email,pw,confirmPw,hasTriedSubmission])

  useEffect(()=>{
    validateParams();
  },[firstName,lastName,contactNum,countryCode,email,pw,confirmPw,hasTriedSubmission,validateParams])

  return (
    <form className='d-flex flex-column justify-content-center rounded outline-dark p-4 m-0' onSubmit={e=>{e.preventDefault();e.stopPropagation()}}>
      <img className='my-4 mx-auto' src={require("../../../assets/img/logo.png")} alt="" style={{maxWidth:"50%"}}/>
      <h1 className='mb-3 mx-auto'>Sign Up</h1>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="First name" aria-label="firstName" aria-describedby="basic-addon1"
					value={firstName}
          onChange={e=>setFirstName(e.target.value)}
          />
      </div>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Last name" aria-label="lastName" aria-describedby="basic-addon1"
          value={lastName}
          onChange={e=>setLastName(e.target.value)}
          />
      </div>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Email" aria-label="email" aria-describedby="basic-addon1"
          value={email}
          onChange={e=>setEmail(e.target.value)}
					/>
      </div>
      <div className="input-group mb-3">
        <input type="password" className="form-control" placeholder="Create Password" aria-label="createPassword" aria-describedby="basic-addon1"
          value={pw}
          onChange={e=>setPw(e.target.value)}
					/>
      </div>
      <div className="input-group mb-3">
        <input type="password" className="form-control" placeholder="Confirm Password" aria-label="confirmPassword" aria-describedby="basic-addon1"
          value={confirmPw}
          onChange={e=>setConfirmPw(e.target.value)}
					/>
      </div>
      <div className="d-flex justify-content-around">
        <div className="dropdown me-2 mb-3 flex-grow-1">
          <button className="btn border-dark-subtle dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                  onClick={e=>{countryCodeSearchRef.current.focus()}}>
            {`${(countryCode!==undefined && countryCode!=="") ? countryCode : "Country code"}`}
          </button>
          <ul className="dropdown-menu" style={{maxHeight:"250px", overflow:"scroll"}}>
            <li className="dropdown-item">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Search" aria-label="lastName" aria-describedby="basic-addon1"
                          value={countryCodeSearchStr}
                          onChange={e=>setCountryCodeSearchStr(e.target.value.toLowerCase())}
                          ref={countryCodeSearchRef}
                        />
                </div>
            </li>
            {data.filter(obj=>obj.name.toLowerCase().includes(countryCodeSearchStr)).map((obj,index)=>
              <li key={index} className='dropdown-item d-flex'
                  onClick={()=>setCountryCode(obj.dial_code)}
                >
                <div className='col-3'>{obj.flag}</div>
                <div className='col-5'>{obj.code}</div>
                <div className='col-2'>{obj.dial_code}</div>
              </li>)}
          </ul>
        </div>
        <div className="input-group mb-3 flex-grow-1">
          <input type="text" className="form-control" placeholder="Contact number" aria-label="contactNumber" aria-describedby="basic-addon1"
            value={contactNum}
            onChange={e=>{
                if(isNaN(e.target.value)) return
                setContactNum(e.target.value)
              }
            }
            />
        </div>
      </div>
      <p className='text-danger'>{errMsg}</p>
      <p className='text-muted'>Already have an account? <NavLink to={"/login"}>Log In</NavLink></p>
      <button className="btn btn-primary" onClick={e=>{setHasTriedSubmission(true);handleSubmit()}}>Create account</button>
    </form>
  )
}

export default SignupForm