import React, { useCallback, useEffect, useState } from 'react'
import ListItem from '../components/ListItem'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import { useSearchStr } from '../contexts/SearchContext'
import useAxios from '../Hooks/auth/useAxios'


function Home() {
    const [testNames, setTestNames] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const searchStr = useSearchStr()
    const api = useAxios()

    const getTests = useCallback(async() => {
        try {
            const res = await api.get(`/test?q=${searchStr??""}`)
            setTestNames(res.data)
            setIsLoading(false)
        } catch(e) {
            setTestNames(null)
            setIsLoading(false)
        }
    // eslint-disable-next-line
    },[searchStr])

    useEffect(()=>{
        setIsLoading(true)
        getTests()
    }, [searchStr, getTests])

    const handleTestEdit = (testId)=>{

    }

    const handleTestDelete = async (testId)=>{
        setIsLoading(true)
        let success = await deleteFormElems(testId)
        if(!success) {
            alert("Error deleting test!")
            return
        }
        success = await deleteTest(testId)
        if(!success) {
            alert("Error deleting test!")
            return
        }
    }
    
    const deleteFormElems = async (testId) => {
        try {
            await api.delete(`/formElem/${testId}`)
            setIsLoading(false)
            return true;
        } catch(e) {
            console.log(e);
            setIsLoading(false)
            return false
        }
    }
    
    const deleteTest = async (testId) => {
        try {
            await api.delete(`/test/${testId}`)
            setTestNames(prev=>prev.filter((test)=>test.id!==testId))
            setIsLoading(false)
            return true
        } catch(e) {
            console.log(e);
            setIsLoading(false)
            return false;
        }
    }

  return (
    <div>
        <h1 className='my-2'>Home</h1>
        <div>
            {isLoading ? 
                <div className="mt-4 d-flex justify-content-center">
                    <Loader />
                </div> 
            :
                (!testNames) ? 
                    <>
                        <h1 className='text-center mt-5'> Unable to get tests.</h1>
                        <h4 className='text-center m2-2'> Please try again later.</h4>
                    </>
                :
                    (testNames.length===0) ? 
                        <><h2 className='text-center my-3'>No tests found.</h2></> 
                    :
                        testNames.map((testName, index)=>{
                        return (
                            <Link to={`/calc/${testName.id}`} style={{textDecoration:"none", color:"unset"}} key={index}>
                                <ListItem testId={testName.id} 
                                          title={testName.name}
                                          body={testName.description}
                                          handleDelete={(testID) => {
                                                if (window.confirm(`Are you sure you want to delete "${testName.name}"`)) {
                                                    handleTestDelete(testID)
                                                }
                                            }
                                          }
                                          handleEdit={handleTestEdit}/>
                            </Link>
                    )
            })}
        </div>

    </div>  
  )
}

export default Home