import React from 'react'
import CreateForm from '../components/forms/CreateForm'
import { useAuthContext } from '../contexts/AuthContext'
import { Navigate } from 'react-router-dom'
import { USER_ROLES } from '../config/config'
import Forbidden from './Forbidden'

function Create() {
  const authContext = useAuthContext()
  return (
    <>
      {(authContext.user) ?      // lower role = more powerful
        (authContext.user.role <= USER_ROLES.ADMIN) ?
            <div className="container" style={{maxWidth:"600px"}}>
                <CreateForm />
            </div>
            :
            <Forbidden />
          :
          <Navigate to={"/login"}/> 
        }
    </>
  )
}

export default Create