import { useAuthContext } from '../../contexts/AuthContext'
import axios from 'axios'
import dayjs from 'dayjs'
const BASE_URL = process.env.REACT_APP_BASE_URL

const useAxios = () => {
    const {token, user, setToken, logout} = useAuthContext()

    const AxiosInstance = axios.create({
        baseURL:BASE_URL,
        headers: {Authorization: `Bearer ${token}`}
    })

    AxiosInstance.interceptors.request.use(async (req) => {
        const controller = new AbortController();
        if(user) {
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
            if(!isExpired) return req
        }
        try {
            const res = await axios.post(`${BASE_URL}/token`,{}, {withCredentials:true})
            setToken(res.data.token)
            
            localStorage.setItem('authToken', res.data.token);
            req.headers.Authorization = `Bearer ${res.data.token}`
        } catch(e) {
            console.log("Error refreshing token", e);
            controller.abort()
            logout()
        }
        return req
    })
    

    return AxiosInstance
}

export default useAxios