import React from 'react'
import { Link } from 'react-router-dom'
import AllCenter from '../layouts/AllCenter'

function Error() {
  return (
    <>
      <AllCenter>
          <h1 className="mt-4">Whoops! This page does not exist.</h1>
          <h4>Click the home button to head back home.</h4>
          <Link to={"/"}>
              <button className="btn btn-dark">Home</button>
          </Link>
      </AllCenter>
    </>
  )
}

export default Error