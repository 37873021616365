import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_BASE_URL

const AuthContext = React.createContext()

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [token, setToken] = useState(localStorage.getItem("authToken"))
    const [user, setUser] = useState((localStorage.getItem("authToken")) ? jwtDecode(localStorage.getItem("authToken")) : null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
        if(!token) {
            localStorage.removeItem("authToken")
            return
        }
        localStorage.setItem("authToken", token)
        setUser(jwtDecode(token))
    }, [token])

    const login = async (email, password)=>{
        setIsLoading(true)
        const url = BASE_URL + "/user/login"
        try {
            const res = await axios({
                method: 'post',
                url: url,
                headers: {"Content-Type":"application/json"},
                data:  { email, password },
                withCredentials:true
            })

            setToken(res.data.accessToken)
            setIsLoading(false)
            navigate("/")
            return {loginSuccess:true, message:"Log in successful!"};
        } catch(e) {
            console.log(e);
            setIsLoading(false)
            return {loginSuccess:false, message:e.response?.data.message};
        }
    }

    const logout = async ()=>{
        setIsLoading(true)
        setToken(null)
        setUser(null)
        navigate("/login")
        const url = BASE_URL + "/user/logout"
        try {
            await axios({
                    method: 'delete',
                    url: url,
                    withCredentials:true
                })
        } catch(e) {
            console.log(e);
        }
        setIsLoading(false)
    }

    const canUserAccess = (role) => {
        return user.role <= role
    }

    return (
        <AuthContext.Provider value={{
            user,
            token,
            isLoading,
            setToken,
            login,
            logout,
            canUserAccess
        }}>
            {children}
        </AuthContext.Provider>
    )
}