import { Outlet} from 'react-router-dom';
import './assets/css/App.css';
import Navbar from './components/Navbar';
import { SearchProvider } from './contexts/SearchContext';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
      <SearchProvider>
        <AuthProvider>
            <div className="App">
              <Navbar />
              <div className='container py-3'>
                    <Outlet />
                </div>
            </div>
        </AuthProvider>
      </SearchProvider>
  );
}

export default App;
