import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext.js';

const LoginForm = () => {
    const navigate = useNavigate()
    const AuthContext = useAuthContext()

    const [email, setEmail] = useState("");
    const [pw, setPw] = useState("");

    const [errMsg, setErrMsg] = useState("")

    useEffect(()=>{
      if(AuthContext.token) {
        navigate("/")
      }
    })
  
    const resetForm = () => {
      setEmail("")
      setPw("")
      setErrMsg("")
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault()
      e.stopPropagation()

      if(!validateParams()) return;

      const {loginSuccess, message} = await AuthContext.login(email.trim().toLowerCase(), pw.trim())
      if(!loginSuccess) {
        setErrMsg(message)
        return
      }
      resetForm()
      navigate("/")
    }

    const validateParams = ()=>{
      if(email===undefined || email==="") {
        setErrMsg("Email cannot be blank.");
        return false;
      }
      
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(email)) {
        setErrMsg("Please enter a valid email address.");
        return false;
      }
  
      if(pw===undefined || pw==="") {
        setErrMsg("Password cannot be blank.");
        return false;
      }
      setErrMsg("")
      return true;
    }
  
    return (
      <form className='d-flex flex-column justify-content-center rounded outline-dark p-4 m-0'
            onSubmit={handleSubmit}
            >
        <img className='my-4 mx-auto' src={require("../../../assets/img/logo.png")} alt="" style={{maxWidth:"50%"}}/>
        <h1 className='mb-3 mx-auto'>Log In</h1>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Email" aria-label="email" aria-describedby="basic-addon1"
            value={email}
            onChange={e=>setEmail(e.target.value)}
                      />
        </div>
        <div className="input-group mb-3">
          <input type="password" className="form-control" placeholder="Password" aria-label="enterPw" aria-describedby="basic-addon1"
            value={pw}
            onChange={e=>setPw(e.target.value)}
                      />
        </div>
        <p className='text-danger'>{errMsg}</p>
        <p className='text-muted'>Don't have an account? <NavLink to={"/signup"}>Sign Up</NavLink></p>
        <button type='submit' className="btn btn-primary">
          Log In
        </button>
      </form>
    )
}

export default LoginForm