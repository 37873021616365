import React, { useEffect, useMemo, useState } from 'react'
import "../../../assets/css/components/form/sections/sectionStyle.css"
import {v4 as uuidv4} from "uuid"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashCan} from "@fortawesome/free-regular-svg-icons"
import RadioOption from './RadioOption'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const AddRadioInput = ({preset=null, handleCreate, handleDelete, handleEdit}) => {
  /**
   * {
      "id":"elem-2024-06-12-1000",
      "testId":"test-2024-06-12-1000",
      "type": "radio",
      "required": true,
      "fields": [
          {
            "id": 1,
            "label": "<2 mg/dL (<34.2 µmol/L)",
            "val": 1
          },
          {
            "id": 2,
            "label": "2-3 mg/dL (34.2-51.3 µmol/L)",
            "val": 2
          },
          {
            "id": 3,
            "label": ">3 mg/dL (>51.3 µmol/L)",
            "val": 3
          }
      ],
      "attr": {
          "label": "Bilirubin (Total)"
      }
    },
   */
  const uuid = useMemo(() => uuidv4(), [])
  const [isRqd, setIsRqd] = useState(false);
  const [label, setLabel] = useState("");
  const [notes, setNotes] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [options, setOptions] = useState([]);
//   const [opt, setOpt] = useState(null)
  const [isAdding, setIsAdding] = useState(false)

  const handleAddOption = (option)=> {
    setOptions(prev=>[...prev, option])
    setIsAdding(false)
  }

  const handleDeleteOption = (id)=> {
    setOptions(prev=>prev.filter(item=>(item.id!==id)))
  }

const handleCancelAdding = ()=>{
      setIsAdding(false)
  }

  useEffect(()=>{
    setOptions(prev=>[...prev])
    if(!preset) return;
    setIsRqd(preset.required)
    setLabel(preset.attr.label)
    setNotes(preset.attr.lblNotes)
    setOptions(preset.fields)
  }, [preset])

//   useEffect(()=>{
//     console.log({
//         "id":uuid,
//         "type": "number",
//         "required": isRqd,
//         "units": units,
//         "attr": {
//             "label": label,
//             "lblNotes": notes,
//             "placeholder": placeholder,
//             "min": min,
//             "max": max
//         }
//     })
//   }, [isRqd, units, label, placeholder, notes, min, max, uuid])

  return (
    <form className='rounded outline-dark p-4'>
        <h5 className='mb-3'>Radio Input</h5>
        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-lbl`}>Label <sup className='text-danger ms-1'>*</sup></span>
          <input type="text" id={`${uuid}-lbl-input`} className="form-control" placeholder="Enter Label" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={label} onChange={(e)=>{setLabel(e.target.value)}}/>
        </div>

        <h6>Add Options<sup className='text-danger ms-1'>*</sup></h6>
        <div className="container mb-3">
            {options.map((opt, index)=>{
                return (
                <div key={index} className='outline-dark rounded p-2 mb-3'>
                    <div className="d-flex">
                        <span className='me-2'>{index+1}.</span>
                        <div className="d-flex flex-column flex-grow-1">
                            <span>Option Label: {opt.label}</span>
                            <span>Value: {opt.val}</span>
                        </div>
                        <button className='btn btn-danger ms-2 mt-3'
                                onClick={(e)=>{
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleDeleteOption(opt.id)
                                }}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </button>
                    </div>
                </div>)
            })}
        </div>
        {isAdding && <RadioOption uuid={uuidv4()} handleAddOption={handleAddOption} handleCancelAdding={handleCancelAdding}/>}

        <button className='btn btn-primary mb-3' 
                                onClick={(e)=>{
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setIsAdding(true)
        }}>
            Add Option <FontAwesomeIcon icon={faPlus}/>
        </button>

        <div className="input-group mb-3">
          <span className="input-group-text" id={`${uuid}-notes`}>Notes</span>
          <textarea type="text" id={`${uuid}-notes-input`} className="form-control" placeholder="Enter Notes" aria-label="Test Name" aria-describedby="basic-addon1"
                 value={notes} onChange={(e)=>{setNotes(e.target.value)}}/>
        </div>

        <div className="form-check form-switch mb-3">
          <input className="form-check-input" type="checkbox" role="switch" id={`${uuid}-reqd`} checked={isRqd} 
                 value={isRqd} onChange={(e)=>{setIsRqd(prev=>!prev)}}/>
          <label className="form-check-label" htmlFor={`${uuid}-reqd`}>Required</label>
        </div>
        
        <p className="text-danger">{errMsg}</p>

        <div className="d-flex">
            <button className='btn btn-primary me-2' onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        if(!label || label===undefined || label.trim()==='') {
                            setErrMsg("Label is a required field.")
                            return;
                        } else if(options.length < 2) {
                            setErrMsg("Please add at least 2 options.")
                            return
                        }

                        const obj = {
                            "id":(!preset) ? uuid : preset.id,
                            "type": "radio",
                            "required": isRqd,
                            "fields": options,
                            "attr": {
                                "label": label.trim(),
                            },
                            "notes": notes?.trim(),
                        }

                        if(!preset) {
                            handleCreate(obj);
                        } else {
                            handleEdit(obj);
                        }
            }}>
                {!preset ? "Create Field" : "Edit"}
            </button>
            <button className='btn btn-danger' onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        handleDelete(uuid);
            }}>
                <FontAwesomeIcon icon={faTrashCan}/>
            </button>
        </div>
    </form>
  )
}

export default AddRadioInput