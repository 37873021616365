import React from 'react'
import SignupForm from '../../components/forms/auth/SignupForm'

const SignupView = () => {
  return (
    <div className="mx-auto" style={{maxWidth:"450px"}}>
        <SignupForm />
    </div>
  )
}

export default SignupView