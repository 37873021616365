import React, { useContext, useState } from 'react'

const SearchContext = React.createContext()
const SearchUpdateContext = React.createContext()

export function useSearchStr() {
    return useContext(SearchContext)
}

export function useSearchStrUpdate() {
    return useContext(SearchUpdateContext)
}

export function SearchProvider({children}) {
    const [searchStr, setSearchStr] = useState("")

    const updateSearchStr = (str)=>{
        setSearchStr(str)
    }
    
    return (
        <SearchContext.Provider value={searchStr}>
            <SearchUpdateContext.Provider value={updateSearchStr}>
                {children}
            </SearchUpdateContext.Provider>
        </SearchContext.Provider>
    )
}