import React, { useEffect, useState } from 'react'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import { useSearchStrUpdate } from '../contexts/SearchContext'
import { useAuthContext } from '../contexts/AuthContext';
import { USER_ROLES } from '../config/config';

function Navbar() {
    const navigate = useNavigate();
    const [str, setStr] = useState("")
    const updateSearchStr = useSearchStrUpdate()    // Updates search context
    const authContext = useAuthContext();

    useEffect(()=>{
        const timer = setTimeout(()=>{
            updateSearchStr(str)
        }, [500])
        return () => {
            clearTimeout(timer)
        }
    }, [str, updateSearchStr])

    const handleSearchClick = ()=> {
        if(!authContext.user) return
        navigate("/");
    }

    const handleLogout = (e)=> {
        e.preventDefault();
        authContext.logout()
    }

    return (
        <nav className="navbar navbar-dark bg-dark sticky-top">
            <div className="container-fluid d-flex  justify-content-around">
                <NavLink to="/" className="navbar-brand flex-grow-1 me-0">
                     <img src={require("../assets/img/logo-light.png")} alt="" width="75"/>
                </NavLink>
                <form className="nav-item nav-link flex-grow-1" role="search"
                      onFocus={()=>{
                        if(!authContext.user) return;
                        return <Navigate to="/"/>}
                    }
                      onSubmit={e=>{e.preventDefault();e.stopPropagation();}}>
                    <input className="form-control" type="search" placeholder="Search" aria-label="Search"
                        value={str}
                        onChange={(e)=>setStr(e.target.value)}
                        onClick={handleSearchClick}
                        onFocus={handleSearchClick}
                        disabled={!authContext.user}
                        />
                </form>
                <div className="d-flex flex-row-reverse flex-grow-1">
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="offcanvas offcanvas-end text-bg-dark" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                <div className="offcanvas-header">
                    <h4 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                        {authContext.user ?
                            <>
                                {'Hi, '}
                                <span className='text-capitalize'>{authContext.user.firstName}</span>
                                {'!'}
                            </>
                            :
                            `Menu`
                        }
                    </h4>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                        {authContext.user?.role <= USER_ROLES.ADMIN &&
                            <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link`} to="/create" style={{textDecoration:"none"}}>
                                <button className="btn btn-success">
                                    Create
                                </button>
                            </NavLink>}
                            <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link`} to="/" style={{textDecoration:"none"}}
                                    onClick={()=>setStr("")}>
                                Home
                            </NavLink>
                            <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link`} to="/about" style={{textDecoration:"none"}}>
                                About
                            </NavLink>
                            <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link`} to="/contact" style={{textDecoration:"none"}}>
                                Contact Us
                            </NavLink>

                            {!authContext.user ? <>
                                <div className="d-flex">
                                    <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link me-2`} to="/login" style={{textDecoration:"none"}}>
                                        <button className="btn btn-outline-success">
                                            Log In
                                        </button>
                                    </NavLink>
                                    <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link`} to="/signup" style={{textDecoration:"none"}}>
                                        <button className="btn btn-success">
                                            Sign Up
                                        </button>
                                    </NavLink>
                                </div>
                            </> :
                            <NavLink className={`${(isActive)=>(isActive) ? "active" : ""} nav-item nav-link`} to="/login" style={{textDecoration:"none"}}
                                onClick={handleLogout}>
                                Logout
                            </NavLink>
                            }
                    </ul>
                </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar